import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends Component {
  state = {
    mountClass: "unmounted",
  }
  componentDidMount() {
    this.changeClass()
  }
  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <h1>
          AdrianBarn<span className={this.state.mountClass}>.</span>es
        </h1>
        <p>
          {`👋 Hello, My name is Adrian and I'm a software engineer.`}
          <br />
          {`📱 I build web applications from front to back.`}
        </p>

        <p>
          {`
            I have been in web development for over 10 years. I love building fast, responsive and interactive experiences.
          `}
        </p>
        <p>
          {`
            On the frontend I am focused on building applications in ReactJS, Gatsby, Vue but go way back with jQuery or just plain old JavaScript. I consider myself very experienced when it comes to building interactions; I favor the use of CSS3 Animations and have experience with three.js and GSAP libraries.
          `}
        </p>
        <p>
          {`
            On the backend I work primarily with Rails and PostgreSQL but I also have experience working with NodeJS, PHP, MySQL and MongoDB.
          `}
        </p>
        <p>
          {`
            My work experience includes but is not limited to Multi-million dollar Ecommerce Sites (Billabong Global), Full Stack Enterprise Grade Single-Page App Development (Blast and Fexa) and have served as a team lead manager in various positions ensuring projects are organized and completed in reasonable timeframes.
          `}
        </p>
        <p>
          Brands I have worked with: &nbsp;
          <a href="https://soberlink.com">Soberlink</a>, &nbsp;
          <a href="https://fexa.io">Fexa</a>, &nbsp;
          <a href="https://blast.com">Blast</a>, &nbsp;
          <a href="https://billabong.com">Billabong</a>, &nbsp;
          <a href="https://rvca.com">RVCA</a>, &nbsp;
          <a href="https://vonzipper.com">Von Zipper</a>, &nbsp;
          <a href="https://elementbrand.com/">Element</a>, &nbsp;
          <a href="https://www.stompgrip.com/">Stompgrip</a>, &nbsp;
          <a href="https://futuresfins.com/">Futures</a>
        </p>
      </Layout>
    )
  }
  changeClass = () => {
    setTimeout(() => {
      this.setState({
        mountClass: "mounted",
      })
    }, 5)
  }
}

export default IndexPage
